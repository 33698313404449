import React, { useState, useRef, useEffect } from "react"
import BlockSolution from "../../components/blockSolution/blockSolution"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import BlockContainerList from "../../components/blockContainerList/BlockContainerList"
import ButtonStandard from "../../components/buttonStandard/buttonStandard"
import BlockMateriels from "../../components/blockMateriels/BlockMateriels"
import BlockApp from "../../components/blockApp/blockApp"
import BlockExperience from "../../components/blockExperience/BlockExperience"
import utils from "../../utils/utils"
import { useStaticQuery, graphql } from "gatsby"
import { getTaxoSummaryMaterials } from "../../hooks/hooks"
import ImgCustom from "../../components/imgCustom/imgCustom"
import "./index.scss"
import links from "../../utils/links"
import tracking from "../../utils/tracking"

const controller = () => {
  const dataGraphQl = useStaticQuery(graphql`
    query {
      nodePageOffre {
        field_seo_titre
        field_seo_description
        field_cta_materiels_link
        field_titre_materiel_summary
        field_sous_titre_
        relationships {
          field_hero_header {
            field_cover {
              alt
            }
            field_sous_titre {
              value
            }
            field_titre_hero {
              value
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_tablette {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
          }
        }
      }
      allTaxonomyTermApplication {
        nodes {
          name
          field_cover {
            alt
          }
          field_cover_en_mobile {
            alt
          }
          field_image_application {
            alt
          }
          field_image_application_mobile {
            alt
          }
          field_min_description
          field_sous_titre_ligne
          field_titre
          relationships {
            field_cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
            field_cover_en_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
            field_image_application {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
            field_image_application_mobile {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
          }
        }
      }
      allTaxonomyTermFormule {
        nodes {
          name
          field_detail_cta_souscription
          field_detail_cta_switch_
          field_detail_slogan_bas_de_block
          field_detail_step_description
          field_detail_titre
          field_label
          field_prix
          relationships {
            field_detail_avec_icone {
              field_titre_formule
              field_sous_titre_ligne
              relationships {
                field_img {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, formats: NO_CHANGE)
                    }
                  }
                }
              }
            }
          }
        }
      }
      allMaterialsJson {
        nodes {
          name
          data {
            label
            icon
            description
            isAdvanced
          }
        }
      }
      allImageSharp {
        nodes {
          fluid {
            originalImg
            originalName
          }
        }
      }
    }
  `)

  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const formatedListDetail = detail => {
    let card = {
      odd: [],
      even: [],
    }
    detail.forEach((el_detail, index) => {
      const line = {
        icon: el_detail.relationships.field_img?.localFile,
        label: el_detail.field_titre_formule,
        description: el_detail.field_sous_titre_ligne,
      }
      const result = index % 2 !== 0 ? "even" : "odd"
      card[result].push(line)
    })
    return card
  }
  const dataFormulesFormated = dataGraphQl.allTaxonomyTermFormule.nodes.map(
    el => ({
      name: el.name,
      details: {
        title: el.field_detail_titre,
        price: el.field_prix,
        listDetail: formatedListDetail(
          el.relationships.field_detail_avec_icone
        ),
        checklist: el.field_detail_step_description,
        direction: {
          label: el.field_detail_cta_souscription,
          url: "/",
        },
        ctaSwith: el.field_detail_cta_switch_,
      },
    })
  )
  const dataFormule = {
    initiale: {
      addClassContainer: "jc-lg-fs",
      stepInstructions: [
        {
          stepIndex: "1",
          name: "alarm",
          label: "Une alarme se déclenche",
          icon: "item_alarm",
        },
        {
          stepIndex: "2",
          name: "phone",
          label: "Vous recevez une notification sur votre smartphone",
          icon: "item_phone_alert",
        },
        {
          stepIndex: "0",
          name: "response",
          label: "",
          icon: "item_initiale_response",
        },
        {
          stepIndex: "3",
          name: "call",
          label: "Un opérateur analyse la situation",
          icon: "item_call_center",
        },
        {
          stepIndex: "4",
          name: "alarm_response",
          label: "Il contacte les forces de l’ordre si la situation l’exige",
          icon: "item_alarm_yellow",
        },
      ],
      details: dataFormulesFormated.filter(el => el.name === "initiale")[0]
        .details,
    },
    integrale: {
      addClassContainer: "",
      stepInstructions: [
        {
          stepIndex: "1",
          name: "alarm",
          label: "Une alarme se déclenche",
          icon: "item_alarm",
        },
        {
          stepIndex: "2",
          name: "call",
          label: "Un opérateur analyse la situation",
          icon: "item_call_center",
        },
        {
          stepIndex: "3",
          name: "alarm_response",
          label: "Il contacte les forces de l’ordre si la situation l’exige",
          icon: "item_alarm_yellow",
        },
      ],
      details: dataFormulesFormated.filter(el => el.name === "integrale")[0]
        .details,
    },
  }
  const blockSolution = utils.mapHeroHeader(dataGraphQl, "nodePageOffre")
  const materiels = getTaxoSummaryMaterials()
  const [formule, setFormule] = useState("initiale")
  const targetDomFormule = useRef(null)
  const handleChangeFormule = target => {
    setFormule(target)
    window.history.replaceState({}, "", "/formules/#" + target + (isTherecampagne ? "/" + campagne : ""))
    if (targetDomFormule.current) {
      window.scrollTo({
        top: targetDomFormule.current.offsetTop - 65,
        behavior: "smooth",
      })
    }
  }
  const applicationInitiale = utils.mapAppByName(
    dataGraphQl.allTaxonomyTermApplication.nodes,
    "application formule initiale"
  )
  const applicationIntegrale = utils.mapAppByName(
    dataGraphQl.allTaxonomyTermApplication.nodes,
    "application formule intégrale"
  )
  const blockApp = {
    initiale: utils.mapApp(applicationInitiale),
    integrale: utils.mapApp(applicationIntegrale),
  }

  const seoPage = {
    title: dataGraphQl.nodePageOffre.field_seo_titre,
    description: dataGraphQl.nodePageOffre.field_seo_description,
  }

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "offerpage")
    tracking.track("page_name", seoPage.title)
    tracking.track("categorie", "telesurveillance")
    //tracking.trackinit()
    utils.startPageWindow()
    const hash = location.hash
    const names = dataFormulesFormated.map(e => e.name)

    if (hash && names) {
      const nameWithoutHash = hash.replace("#", "")
      if (names.includes(nameWithoutHash.substring(0,nameWithoutHash.indexOf("/")))) {
        setFormule(nameWithoutHash.substring(0,nameWithoutHash.indexOf("/")))
      } else {
        handleChangeFormule("initiale")
      }
    }
  }, [])

  return (
    <Layout isViewPromoOnHeader={true}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={"formules/"}
      />
      {dataFormule[formule] && (
        <div className="bc-md-w">
          <div className="wrapper">
            {/** BLOCK INDEX TOP */}
            <BlockSolution data={blockSolution} />
          </div>
          <article className="d-f jc-c fd-c ai-c" ref={targetDomFormule}>
            <div className="container-btn-formule py-6">
              <button
                onClick={() => handleChangeFormule("initiale")}
                className={
                  formule === "initiale"
                    ? "btn-formule pr-2 fs-14 lh-24 py-1 ff-gb is-active"
                    : "btn-formule pr-2 fs-14 lh-24 py-1 ff-gbook"
                }
              >
                Formule initiale
              </button>
              <button
                onClick={() => handleChangeFormule("integrale")}
                className={
                  formule === "integrale"
                    ? "btn-formule pr-2 fs-14 lh-24 py-1 ff-gb is-active"
                    : "btn-formule pr-2 fs-14 lh-24 py-1 ff-gbook"
                }
              >
                Formule intégrale
              </button>
            </div>
            {/** BLOCK STEP ICON */}
            <h3 className="ff-gb fs-28 lh-32 ta-c">Comment ça marche ?</h3>
            <section className="py-4 oh ox-lg-s w-100 d-f jc-c">
              <ul
                className={
                  "container-step-formule-design d-f fd-r ai-fs jc-c  ul fd-md-c ai-md-c w-md-100 " +
                  dataFormule[formule].addClassContainer
                }
              >
                {dataFormule[formule].stepInstructions.map((el, index) => (
                  <li
                    key={index}
                    className={
                      index !==
                        dataFormule[formule].stepInstructions.length - 1 &&
                      el.name !== "response"
                        ? "pr-12 pr-md-0 li arrow-step-next"
                        : " li "
                    }
                  >
                    <div
                      className={
                        "item-formule-work d-f fd-c jc-c ai-c mx-md-a py-md-2 " +
                        el.name
                      }
                    >
                      <div className="d-f ai-c jc-c h-100 w-100">
                        {el.stepIndex !== "0" && (
                          <span className="d-n d-md-ib c-1 fs-40 mr-2 ">
                            {el.stepIndex}
                          </span>
                        )}
                        <div
                          className={"icon-formule-work mb-1  " + el.icon}
                        ></div>
                      </div>
                      {el.label && (
                        <p className="mb-0 fs-16 lh-24 ta-c">{el.label}</p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </section>
            {/** BLOCK WORDING */}
            <section className="wrapper no-padding no-wrapper">
              <div className="block-formule-details br-22 bc-2 p-r">
                <div className="round-form"></div>
                <div className="d-f fd-c jc-c ai-c p-r">
                  <h3 className="ff-gb fs-28 lh-40 ta-c">
                    {dataFormule[formule].details.title}
                  </h3>
                  <div className="bcg-2 br-12 px-2 py-1">
                    <p className="mb-0 ff-gbook f-w c-1 fs-20 lh-40">
                      <span className="">À partir de </span>
                      {dataFormule[formule].details.price?.toFixed(2)}€/mois
                    </p>
                  </div>
                  <div className="present-formule d-f fd-md-c w-100 mt-5 py-md-2">
                    {Object.keys(dataFormule[formule].details.listDetail).map(
                      (key, index) => (
                        <div
                          key={index}
                          className={
                            index == 0
                              ? "container-wording pr-1 pr-md-0"
                              : "container-wording pl-1 pl-md-0 "
                          }
                        >
                          <div className="card-wording d-f ai-fs  bc-w br-12">
                            <ul className="ul">
                              {dataFormule[formule].details.listDetail[key].map(
                                (el, i) => (
                                  <li key={"child" + i} className="li">
                                    <div className="d-f ai-fs item-wording-detail">
                                      <span
                                        style={{
                                          height: "20px",
                                          minWidth: "20px",
                                          width: "20px",
                                        }}
                                      >
                                        <ImgCustom src={el.icon} alt={""} />
                                      </span>
                                      <div className="ml-2">
                                        <p className="mb-0 fs-14 lh-24">
                                          {el.label}
                                        </p>
                                        {el.description && (
                                          <p className="mb-0 fs-14 lh-24 ff-gbook">
                                            {el.description}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <BlockContainerList>
                    {dataFormule[formule].details.checklist.map((el, index) => (
                      <li
                        style={{ maxWidth: "305px" }}
                        key={index}
                        className="li d-f ai-c py-md-1"
                      >
                        <span
                          style={{
                            height: "20px",
                            minWidth: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                          className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                        ></span>
                        <p className="mb-0 fs-14 lh-24 ff-gm cg-7">{el}</p>
                      </li>
                    ))}
                  </BlockContainerList>
                  <div style={{ width: "fit-content" }} className="mx-a mt-2">
                    <ButtonStandard
                      label={dataFormule[formule].details.direction.label}
                      url={links.getLink("diag") + campagne}
                      addClass="bc-1 c-w btn-hover-c1"
                    />
                  </div>
                  <p className="mb-0 fs-14 lh-24 mt-3 ta-c d-f fd-r ai-c fd-md-c ">
                    {formule === "integrale"
                      ? "je préfère gérer mes alertes : "
                      : "Je préfère la gestion automatique des alertes : "}
                    <button
                      onClick={() =>
                        handleChangeFormule(
                          formule === "integrale" ? "initiale" : "integrale"
                        )
                      }
                      className="c-1 td-u-c1 ff-gbook"
                    >
                      {dataFormule[formule].details.ctaSwith}
                    </button>
                  </p>
                </div>
              </div>
            </section>
            {/** Block Materiel on Formule */}
            <div className="wrapper">
              <div className="d-f fd-c jc-c ai-c py-12">
                <h3 className="fs-34 ff-gb lh-48 cg-7 ta-md-c">
                  {dataGraphQl.nodePageOffre.field_titre_materiel_summary}
                </h3>
                <p className="mb-4 ta-md-c">
                  {dataGraphQl.nodePageOffre.field_sous_titre_}
                </p>
                <div className="block-wrapper-materiels d-f fd-c">
                  {materiels && <BlockMateriels data={materiels} />}
                  <div
                    style={{ width: "fit-content" }}
                    className="mx-a mt-4 mt-md-8"
                  >
                    <ButtonStandard
                      url={links.getLink("materiel") + campagne}
                      addClass="with-line td-n w-s-n bc-w c-1 f-w-b btn-hover-c1-line"
                      label={
                        dataGraphQl.nodePageOffre?.field_cta_materiels_link
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/** Block APP */}
            <BlockApp
              data={blockApp[formule]}
              addClassLabel={"w-s-n w-md-167 ta-md-c"}
              addClass={"py-5"}
            />
            {/**Block Experience */}
            <BlockExperience />
          </article>
        </div>
      )}
    </Layout>
  )
}

export default controller
